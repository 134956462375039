import './AboutUsStyle.css';

function AboutUs(){
    return(

        <>
        <div className='about'>
        <h1>Our History</h1>
        <p>In Pakistan tour packages 2022, we aim to let every Pakistani and foreigner explore the most beautiful and awe-inspiring areas of Pakistan with every facility and u<br/>tmost comfort. We have pleased many foreign tourists and local adventure enthusiasts with our excellent services, receiving 100% satisfaction in exploring the northern areas and other <br/> spots of Pakistan. We know all the weather conditions, traffic situations, road constructions, and other possible hurdles beforehand. We plan for the most suitable Pakistan tour packages to make them successful and adventurous.

Pakistan Tour and Travel have a wide range of Pakistan tour packages for you at relatively affordable prices. Whether you want to travel from which city, you can<br/> choose any of our travel packages, including Pakistan tour packages from Lahore, Pakistan tour packages from Karachi, and Northern areas of Pakistan Tour packages. Our marketing team and highly skilled team guide you<br/> about the best and most appropriate tour package for you according to your requirements. Among more than 100 Pakistan Tour Packages, now you can choose your favorite one in any season to have a never-forgetting vacation with your loved ones</p>
 
  <h1>Most Reasonable Yet Top Of Pakistan Tour Packages</h1>
  <p>Whether you are looking for family Pakistan tour packages, couple tour packages, or packages for friends to enjoy the heavenly areas of Pakistan, Pakistan Tour and travel have everything to offer you. The Pakistan tour packages for northern areas are best in summer, <br/>but you can choose any weather which suits you the best.

These travel destinations in Pakistan tour packages, full of adventure and beauty, include<br/>

Gurez Valley, <br/>
Neelum Valley,<br/>
Skardu Valley,<br/>
Nagar Valley,<br/>
Phandar Valley,<br/>
Hunza Valley.<br/>
Enjoy and explore the natural habitat of Pakistan amongst the majestic Karakoram and Himalaya mountain ranges with Pakistan Tour and Travel</p>
  <h1>Geography of Pakistan</h1>
  <p>The geography of Pakistan is a combination and fusion of multiple lands, including deserts, landscapes, forests, beaches, mountains, hills, grounds, and many more. In a nutshell, the land is everything you want to witness, from clouds to underwater. Pakistan is a country where Highways like the GT road, Motorway, and Karakoram connect different cities and areas.<br/> Located in Asia, the government is famous for its attractions, whether China Border or Northern Areas o KPK. It has five provinces, including Punjab, Sindh, Balochistan, KPK, and Gilgit Baltistan, each of which has unique beauty and tourist spots.

This 36th largest country in the world has neighboring countries like India, Afghanistan, China, and Iran with connecting borders. Geographically, nothing is missing in Pakistan that could hold the tourists back from booking Pakistan tour packages and visiting its beautiful<br/> tourist attractions. In the southern areas, the plains and plateaus attract tourists to explore more of them, whereas; the hills, lakes, and mountain ranges of Karakoram are no less than heaven. K2, the world’s second-highest peak of 8000 meters, is located in the long 500KM Karakoram mountain ranges.

Pakistan has been an underrated travel destination for many years. But, now it has gained popularity worldwide, and we are there for all foreigners and Pakistanis with the best Pakistan tour packages to make their travel experience worth it.</p>
        </div>
        </>
    )
}
export default AboutUs;