import './HeroStyle.css';


function Hero(props) {
    return (
        <>
           <div className={props.cName}>
            <img src={props.img} alt="Home"/>
             
             <div className="Hero-text">
                <h1>{props.tittle}</h1>
                <p>{props.text}</p>
                <a href={props.url} className={props.btnClass}> {props.btnText}</a>
             </div>
            </div>
        </>
    )
}
export default Hero; 