
import Hero from './components/Hero';
import Navbar from './components/Navbar';
import AboutPic from './assets/About.jpg';
import Footer from './components/Footer';
import AboutUs from './components/AboutUs';
function About() {
    return (
        <>
            <Navbar/>
            <Hero
             cName="hero-mid"
             img={AboutPic}
             tittle="About"
             btnClass="hide"
             />
             <AboutUs/>
             <Footer/>
        </>
    )
}
export default About; 