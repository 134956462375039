import './DestinationStyle.css';
import des1 from '../assets/des (1).jpg';
import des2 from '../assets/des (2).jpg';
import des3 from '../assets/des3 (2).jpg';
import des4 from '../assets/des3 (1).jpg';

function Destination() {
    return (
        <>
            <div className="Destination" >
                <h1>Popular Destination</h1>
                <p>Tourism in Pakistan Tourist Attractions, Challenges and Potential </p>

                <div className="first-Des" >
                    <div className="des-text">
                        <h2>Hunza Valley</h2>
                        <p>Nestled in the Karakoram Mountain Range, Hunza comprises several valleys and colourful villages. It is the home of the most helpful, liberal, and hospitable people.

                            Among the orchards, glaciers <br/> meadows skirted by a majestic lake – Attabad Lake, and wooden bridges, Hunza offers hypnotic sunset views.
           <br/>
                            The Altit and Baltit forts, Passu Cones, and Eagles Nest are some of the most popular areas among those visiting.
                            
                            An amalgam of diverse cultures, the beauty of <br/> Pakistan is translated through the remains of ancient civilizations, religious sites, monuments still alive singing tales of the battles fought, serene valleys with snow-capped peaks and frozen lakes, beautiful shores, and lush green forests
                            </p>
                    </div>
                <div className="des-img">
                    <img src={des1} alt="Mountain"/>
                    <img src={des2} alt="Mountain"/>
                </div>
                </div>




                <div className="first-Des" >
                <div className="des-img">
                    <img src={des3} alt="Mountain"/>
                    <img src={des4} alt="Mountain"/>
                </div>
                    <div className="des-text">
                        <h2>HINGOL NATIONAL PARK</h2>
                        <p>Hingol National Park is technically in Pakistan, but it looks more like a Martian planet! The park is over 6,000-square-kilometres and contains incredibly unique rock formations, vast canyons, numerous animal species <br/> and even a mud volcano.

What’s more, part of the National  <br/>Park hugs the coast, adding the ocean to all its other assets. Though totally out-of-this-world in its looks, Hingol is only 3.5 hours from Karachi,  <br/>Pakistan’s most populous city.

Pakistanis shouldn’t have any problem entering the park, but foreigners have had mixed experiences. 
                            </p>
                    </div>
             
                </div>
            </div>
        </>
    )
}

export default Destination;