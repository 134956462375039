export const menuItems=[
    {
        tittle : "Home",
        url :"/",
        cName:"nav-links",
        icon: "fa-solid fa-house-user"
    },
    {
        tittle : "About",
        url :"/about",
        cName:"nav-links",
        icon: "fa-solid fa-circle-info"
    },
    {
        tittle : "Services",
        url :"/services",
        cName:"nav-links",
        icon: "fa-solid fa-briefcase"
    },  {
        tittle : "Contacts",
        url :"/contact",
        cName:"nav-links",
        icon: "fa-solid fa-address-book"
    } ,{
        tittle : "Signup",
        url :"/signup",
        cName:"nav-links"
    }
]