import Hero from './components/Hero';
import Navbar from './components/Navbar';
import HomePic from './assets/Home.jpg';
import Destination from './components/Destination';
import Trip from './components/Trip';
import Footer from './components/Footer';

function Home() {
    return (
        <>
            <Navbar/>
            <Hero
             cName="hero"
             img={HomePic}
             tittle="Your Journey Your Story"
             text="Choose Your Favourite Destination"
             btnText="Travel Plan"
             url="/"
             btnClass="btn" 
             />
             <Destination/>
             <Trip/>
             <Footer/>
        </>
    )
}
export default Home; 