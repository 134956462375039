import './ContactUSStyle.css';

function ContactUs(){
    return(
        <>
        <div className='Contact'>
            <h1>Send US a message !</h1>
            <form>
            <input placeholder='Name'/>
            <input placeholder='Email'/>
            <input placeholder='Subject'/>
            <textarea placeholder='Message' rows='4'></textarea>
            <button>Send Message</button>
            </form>
            </div>
            </>
    )
}
export default ContactUs;