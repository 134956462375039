
import './TripStyle.css';   
import trip from '../assets/trip.jpg';
import trip2 from '../assets/trip (2).jpg';
import trip3 from '../assets/trip (3).jpg';

function Trip(){
    return (
        <>
         <div className="trip">
         <h1>Recent Trip</h1>
                <p>You can discover unique Destination using Google Map </p>
             <div className="trips">
                <div className="t-card">
                    <div className='t-img'>
                    <img src={trip} alt='trip' />
                    </div>
                    <h4> KALASH VALLEYS </h4>
                    <p>The Kalash Valleys, comprised of Bumboret, Rumbur, and Birir, are home to the Kalash people, a religious and ethnic minority in Pakistan with their own beliefs, culture and language. The valleys they live in are certainly some of the most beautiful places in Pakistan – not just for their natural splendour, but also for the beauty of the Kalash themselves.</p>
                </div>


                <div className="t-card">
                    <div className='t-img'>
                        <img src={trip3} alt='trip' />
                    </div>

                    <h4> KALASH VALLEYS </h4>
                    <p>The Kalash Valleys, comprised of Bumboret, Rumbur, and Birir, are home to the Kalash people, a religious and ethnic minority in Pakistan with their own beliefs, culture and language. The valleys they live in are certainly some of the most beautiful places in Pakistan – not just for their natural splendour, but also for the beauty of the Kalash themselves.</p>
                </div>


                <div className="t-card">
                    <div className='t-img'>
                        <img src={trip2} alt='trip' />
                    </div>
                    <h4> KALASH VALLEYS </h4>
                    <p>The Kalash Valleys, comprised of Bumboret, Rumbur, and Birir, are home to the Kalash people, a religious and ethnic minority in Pakistan with their own beliefs, culture and language. The valleys they live in are certainly some of the most beautiful places in Pakistan – not just for their natural splendour, but also for the beauty of the Kalash themselves.</p>
                </div>
         </div>
         </div>
        </>
    )
}
export default Trip;
