
import './App.css';
// import Navbar from './components/Navbar';
import Home from './Home';
import {Routes , Route} from 'react-router-dom';
import Services from './Services';
import About from './About';
import Contact from './Contact';


function App() {
  return (
    <div className="App">
       {/* <Home/> */}
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/services" element={<Services/>} />
        <Route path="/contact" element={<Contact/>} />
      </Routes>
   
  
    </div>
  );
}

export default App;
