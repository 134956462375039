import './FooterStyle.css';

function Footer(){
    return(
        <>
        <div className="footer">
        <div className="Top">
            <div>
            <h1>Trippy</h1>
            <p>Choose your favourite Destination </p>
           </div>
           <div>
            <a href="/"><i class="fa-brands fa-square-facebook"></i></a> 
            <a href="/"><i class="fa-brands fa-square-instagram"></i></a> 
            <i class="fa-brands fa-square-google-plus"></i> 
            <a href="/"><i class="fa-brands fa-square-twitter"></i></a> 
           </div>
        </div>
        <div className="Buttom">
            <div>
                <h4>Project</h4>
                <a href="/">Changelog</a>
                <a href="/">Licences</a>
                <a href="/">Status</a>
                <a href="/">All Versions</a>
            </div>
            <div>
                <h4>Community</h4>
                <a href="/">GitHub </a>
                <a href="/">Issues</a>
                <a href="/">twitter</a>
                <a href="/">Google</a>
            </div>
            <div>
                <h4>Help</h4>
                <a href="/">Support</a>
                <a href="/">Contact us</a>
                <a href="/">Status</a>
                <a href="/">Troubleshoot</a>
            </div>
            <div>
                <h4>Other</h4>
                <a href="/">Term and Condition</a>
                <a href="/">Licences</a>
                <a href="/">Privacy</a>
               
            </div>
        </div>
        </div>
        </>
    )
}
export default Footer;