import Hero from './components/Hero';
import Navbar from './components/Navbar';
import ServicesPic from './assets/Services.jpg';
import Footer from './components/Footer';
import Trip from './components/Trip';
function Services() {
    return (
        <>
            <Navbar/>
            <Hero
             cName="hero-mid"
             img={ServicesPic}
             tittle="Contact"
             btnClass="hide"
             />
             <Trip/>
             <Footer/>
        </>
    )
}
export default Services; 