
import Hero from './components/Hero';
import Navbar from './components/Navbar';
import ContactPic from './assets/Contact.jpg';
import Footer from './components/Footer';
import ContactUs from './components/ContactUs';
function Contact() {
    return (
        <>
            <Navbar/>
            <Hero
             cName="hero-mid"
             img={ContactPic}
             tittle="Contact"
             btnClass="hide"
             />
             <ContactUs/>
             <Footer/>
        </>
    )
}
export default Contact; 