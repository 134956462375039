import "./NavbarStyle.css";
import {menuItems} from "./menuItems";
import { Component } from "react";
import { Link } from "react-router-dom";

class Navbar extends Component{
    state={clicked:false};
    handleClick=()=>{
     this.setState({clicked: !this.state.clicked})
    }
    render(){
    return(
        <>
         <nav className="Navitems"> 
             <h1 className="Navbar-logo">Trippy</h1>

             <div className="menu-icon" onClick={this.handleClick}>
              <i className={this.state.clicked? "fas fa-times" : "fas fa-bars"}></i>
             </div>

             <ul className={this.state.clicked ? "Nav-menu active" : "Nav-menu"}>
                {menuItems.map((item,index)=>{
                    return(
                        <li key={index}>
                        <Link className={item.cName} to={item.url}>
                        <i className={item.icon}></i> {item.tittle}
                        </Link> 
                        </li>
                    )
                })}
                
             </ul>
         </nav>
        </>
    )}
}

export default Navbar;

